<template>
  <div class="personalInformation">
    <h1 class="title">Persoonlijke gegevens</h1>
    <span class="subtitle">Pas hier je persoonlijke gegevens aan</span>
    <div class="form">
      <div class="form__avatar">
        <a-upload
          name="avatar"
          list-type="picture-card"
          class="avatar-uploader"
          :show-upload-list="false"
          :customRequest="e => getFile(e, 'avatar')"
          :beforeUpload="beforeUpload"
          @change="handleChange"
        >
          <div v-if="imageUrl || userInfo.photoUrl">
            <img class="avatarPreview"
                 :src="imageUrl || userInfo.photoUrl"
                 alt="avatar"/>
            <div
              class="avatar__button"
            >
              <a-spin v-if="personalInformation.avatar.isLoading">
                <a-icon slot="indicator"
                        type="loading"
                        style="color: white"

                        spin/>
              </a-spin>
              <a-icon type="camera"
                      v-if="!personalInformation.avatar.isLoading"
                      :style="{color: 'white'}"
              />
            </div>
          </div>

          <div v-else>
            <svg class="avatarAlt" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
              <path
                d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
            </svg>
            <div
              class="avatar__button"
            >
              <a-icon type="camera" :style="{color: 'white'}"/>
            </div>
          </div>
        </a-upload>
      </div>
      <a-alert
        :message="personalInformation.avatar.successMessage"
        type="success"
        close-text="Nu sluiten"
        v-if="personalInformation.avatar.successMessage.length"
        style="margin-bottom: 10px"
      />
      <div class="form__inlineInputs">
        <div class="formWrapper__form--inputWrapper">
          <a-form-item
            :validate-status="($v.personalInformation.name.name.$dirty && $v.personalInformation.name.name.$invalid) ? 'error' : 'success'"
          >
            <template #help>
              <span
                v-show="$v.personalInformation.name.name.$dirty && $v.personalInformation.name.name.$invalid"
              >
                Onjuiste invoer
              </span>
            </template>
            <a-input
              allow-clear
              id="name"
              v-model="personalInformation.name.name"
              size="large"
              class="formWrapper__form--input"
              @focus="focusHandler('name', true, 'personalInformation')"
              @blur="focusHandler('name', false, 'personalInformation')"
            />
          </a-form-item>

          <label for="name"
                 class="formWrapper__form--label"
                 :class="personalInformation.name.isFocused ? 'focused' : ''"
          >
            Voornaam
          </label>
        </div>
        <div class="formWrapper__form--inputWrapper">
          <a-form-item
            :validate-status="($v.personalInformation.surname.name.$dirty && $v.personalInformation.surname.name.$invalid) ? 'error' : 'success'"
          >
            <template #help>
            <span
              v-show="$v.personalInformation.surname.name.$dirty && $v.personalInformation.surname.name.$invalid"
            >
              Onjuiste invoer
            </span>
            </template>
            <a-input
              allow-clear
              id="surname"
              v-model="personalInformation.surname.name"
              size="large"
              class="formWrapper__form--input"
              @focus="focusHandler('surname', true, 'personalInformation')"
              @blur="focusHandler('surname', false, 'personalInformation')"
            />
          </a-form-item>

          <label for="name"
                 class="formWrapper__form--label"
                 :class="personalInformation.surname.isFocused ? 'focused' : ''"
          >
            Achternaam
          </label>
        </div>
      </div>
      <div class="formWrapper__form--inputWrapper">
        <a-form-item
          :validate-status="($v.personalInformation.positionInCompany.name.$dirty && $v.personalInformation.positionInCompany.name.$invalid) ? 'error' : 'success'"
        >
          <template #help>
            <span
              v-show="$v.personalInformation.positionInCompany.name.$dirty && $v.personalInformation.positionInCompany.name.$invalid"
            >
              {{ `Voer alsjeblieft je functie in je bedrijf in` }}
            </span>
          </template>
          <a-input
            allow-clear
            id="positionInCompany"
            v-model="personalInformation.positionInCompany.name"
            size="large"
            class="formWrapper__form--input"
            @focus="focusHandler('positionInCompany', true, 'personalInformation')"
            @blur="focusHandler('positionInCompany', false, 'personalInformation')"
          />
        </a-form-item>

        <label for="positionInCompany"
               class="formWrapper__form--label"
               :class="personalInformation.positionInCompany.isFocused ? 'focused' : ''"
        >
          Functie in bedrijf
        </label>
      </div>
      <div class="form__inlineInputs">
        <div class="formWrapper__form--inputWrapper">
          <a-form-item
            :validate-status="($v.personalInformation.email.name.$dirty && $v.personalInformation.email.name.$invalid) ? 'error' : 'success'"
          >
            <template #help>
              <span
                v-show="$v.personalInformation.email.name.$dirty && $v.personalInformation.email.name.$invalid"
              >
                Onjuiste invoer
              </span>
            </template>
            <a-input-search
              allow-clear
              id="email"
              :loading="personalInformation.email.loading"
              v-model="personalInformation.email.name"
              size="large"
              class="formWrapper__form--input"
              @focus="focusHandler('email', true, 'personalInformation')"
              @blur="focusHandler('email', false, 'personalInformation')"
            />
          </a-form-item>

          <label for="email"
                 class="formWrapper__form--label"
                 :class="personalInformation.email.isFocused ? 'focused' : ''"
          >
            E-mailadres
          </label>
        </div>
        <div class="formWrapper__form--inputWrapper">
          <a-form-item
            :validate-status="($v.personalInformation.phone.name.$dirty && $v.personalInformation.phone.name.$invalid) || personalInformation.phone.errorText ? 'error' : 'success'"
          >
            <template #help>
          <span
            v-show="($v.personalInformation.phone.name.$dirty && $v.personalInformation.phone.name.$invalid) || personalInformation.phone.errorText"
          >
            {{ personalInformation.phone.errorText || 'Onjuiste invoer' }}
          </span>
            </template>
            <a-input
              allow-clear
              id="phone"
              v-model="personalInformation.phone.name"
              size="large"
              class="formWrapper__form--input"
              @focus="focusHandler('phone', true, 'personalInformation')"
              @blur="focusHandler('phone', false, 'personalInformation')"
            >
              <a-select
                slot="addonBefore"
                style="width: 70px"
                @change="(val) => personalInformation.phone.code = val"
                :default-value="code"
                :key="code"
              >
                <a-select-option value="31">
                  +31
                </a-select-option>
                <a-select-option value="32">
                  +32
                </a-select-option>
                <a-select-option value="380">
                  +380
                </a-select-option>
              </a-select>
            </a-input>
          </a-form-item>
          <label for="name"
                 class="formWrapper__form--label formWrapper__form--label--phone"
                 :class="personalInformation.phone.isFocused ? 'focused' : ''"
          >
            Telefoonnummer
          </label>
        </div>
      </div>
      <a-modal
        :visible="isModalVisible"
        :confirm-loading="isModalLoading"
        @ok="handleOk"
        okText="Check!"
        @cancel="handleCancel"
      >
        <div>
          <div class="formWrapper__form--inputWrapper">
            <a-form-item
              :validate-status="($v.personalInformation.confirmPhoneCode.name.$dirty && $v.personalInformation.confirmPhoneCode.name.$invalid) ? 'error' : 'success'"
            >
              <template #help>
          <span
            v-show="$v.personalInformation.confirmPhoneCode.name.$dirty && $v.personalInformation.confirmPhoneCode.name.$invalid"
          >
            {{ personalInformation.confirmPhoneCode.errorText || 'Onjuiste invoer' }}
          </span>
              </template>
              <a-input-search
                allow-clear
                id="confirmPhoneCode"
                :loading="personalInformation.confirmPhoneCode.loading"
                v-model="personalInformation.confirmPhoneCode.name"
                size="large"
                class="formWrapper__form--input"
                @focus="focusHandler('confirmPhoneCode', true, 'personalInformation')"
                @blur="focusHandler('confirmPhoneCode', false, 'personalInformation')"
              />
            </a-form-item>

            <label for="confirmPhoneCode"
                   class="formWrapper__form--label"
                   :class="personalInformation.confirmPhoneCode.isFocused ? 'focused' : ''"
            >
              Bevestig de telefooncode
            </label>
          </div>
        </div>
      </a-modal>
      <a-alert
        :message="errorMessage"
        type="error"
        close-text="Nu sluiten"
        v-if="errorMessage.length"
        style="margin-bottom: 10px"
      />
      <a-alert
        :message="successMessage"
        type="success"
        close-text="Nu sluiten"
        v-if="successMessage.length"
        style="margin-bottom: 10px"
      />
      <a-button type="primary"
                @click="submitHandler"
                :loading="loading"
                :disabled="!$v.personalInformation.$anyDirty || isSimilar || $v.personalInformation.$invalid"
      >
        Opslaan
        <a-icon type="right"/>
      </a-button>
    </div>
  </div>
</template>

<script>
import {email, minLength, required, sameAs, numeric, maxLength} from "vuelidate/lib/validators";
import {mapActions, mapMutations, mapState} from 'vuex';
import focus from "@/mixins/focus";
import FetchAbstraction from "@/utils/FetchAbstraction";


function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export default {
  name: "PersonalInformation",
  mixins: [focus],
  created() {
    this.changeFields()
  },
  data() {
    return {
      loading: false,
      imageUrl: '',
      errorMessage: '',
      successMessage: '',
      isModalVisible: false,
      isModalLoading: false,
      personalInformation: {
        name: {
          name: '',
          isFocused: true
        },
        surname: {
          name: '',
          isFocused: true
        },
        email: {
          name: '',
          isFocused: true
        },
        phone: {
          errorText: '',
          name: '',
          isFocused: true,
          code: '31',
          loading: false
        },
        positionInCompany: {
          isFocused: false,
          name: '',
        },
        avatar: {
          file: {},
          isLoading: false,
          imageUrl: '',
          successMessage: '',
          errorMessage: ''
        },
        confirmPhoneCode: {
          errorText: '',
          name: '',
          isFocused: false,
        }
      }
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      isUserInfoLoading: state => state.userInfo.isLoading,
      state: state => state
    }),
    code() {
      if (!this.userInfo.phone.code) return '31'
      return this.userInfo.phone.code
    },
    isSimilar() {
      return (
        this.personalInformation.name.name === this.state.userInfo.name &&
        this.personalInformation.positionInCompany.name === this.state.userInfo.position &&
        this.personalInformation.surname.name === this.state.userInfo.surname &&
        this.personalInformation.email.name === this.state.userInfo.email &&
        this.personalInformation.phone.name === this.state.userInfo.phone.number &&
        this.personalInformation.phone.code === this.state.userInfo.phone.code
      )
    }
  },
  methods: {
    ...mapActions([
      'updatePersonalInformation',
      'updateAvatar',
      'verificatePhone',
      'verificateCode',
      'getUser',
    ]),
    submitHandler() {
      this.$v.personalInformation.$touch()
      if (!this.$v.personalInformation.$invalid) {
        if (!(this.personalInformation.phone.name === this.userInfo.phone.number
          && this.personalInformation.phone.code === this.userInfo.phone.code)) {
          this.loading = true
          this.verificatePhone({
            number: this.personalInformation.phone.name,
            code: this.personalInformation.phone.code
          })
            .then(res => {
              if (res.ok) {
                this.isModalVisible = true
              } else {
                this.personalInformation.phone.errorText = res.message
              }
            })
            .finally(() => this.loading = false)
        } else {
          this.loading = true
          this.updatePersonalInformation({
            code: this.personalInformation.phone.code,
            number: this.personalInformation.phone.name,
            position: this.personalInformation.positionInCompany.name,
            email: this.personalInformation.email.name,
            name: this.personalInformation.name.name,
            surname: this.personalInformation.surname.name
          })
            .then(res => {
              if (!res.ok) {
                this.errorMessage = res.message || 'Er is iets misgegaan'
              } else {
                this.successMessage = 'Je hebt succesvol je persoonlijke informatie aangepast!'
              }
            })
            .finally(() => this.loading = false)
        }
      }
    },
    handleOk(e) {
      this.isModalLoading = true
      this.isModalVisible = false
      this.verificateCode({
        number: this.personalInformation.phone.name,
        phoneCode: this.personalInformation.phone.code,
        code: this.personalInformation.confirmPhoneCode.name
      })
      .then(res => {
        if (res.ok) {
          this.updatePersonalInformation({
            code: this.personalInformation.phone.code,
            number: this.personalInformation.phone.name,
            email: this.personalInformation.email.name,
            position: this.personalInformation.positionInCompany.name,
            name: this.personalInformation.name.name,
            surname: this.personalInformation.surname.name
          })
          .then(res => {
            if (!res.ok) {
              this.errorMessage = res.message || 'something went wrong'
            } else {
              this.successMessage = 'Congratulations! You successfully changed your personal information!'
            }
          })
        } else {
          this.personalInformation.confirmPhoneCode.errorText = e.message
        }
      })
      .finally(() => {
        this.isModalLoading = false
        this.personalInformation.confirmPhoneCode.name = ''
      })
    },
    handleCancel(e) {
      this.isModalVisible = false
    },
    getFile({onSuccess, onError, file, onProgress}, title) {
      setTimeout(() => {
        this.onSuccess = true;
        onSuccess("ok");
      }, 0)
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.loading = true;
        return;
      }
      if (info.file.status === 'done') {
        getBase64(info.file.originFileObj, imageUrl => {
          this.imageUrl = imageUrl;
          this.loading = false;
        });
        this.personalInformation.avatar.isLoading = true
        this.personalInformation.avatar.successMessage = ''
        this.updateAvatar(info.file.originFileObj).then(res => {
          if (!res.ok) {
            this.errorMessage = res.photo[0]
          } else {
            this.errorMessage = ''
            this.personalInformation.avatar.successMessage = 'Gefeliciteerd! Je hebt met succes je avatar veranderd!'
          }
        }).finally(() => this.personalInformation.avatar.isLoading = false)
      }
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
      if (!isJpgOrPng) {
        this.errorMessage = 'Photo dient een bestand te zijn van het type: png, jpeg, jpg.';
      }
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.errorMessage = 'Bestand moet kleiner zijn dan 10MB!';
      }
      return isLt10M;
    },
    changeFields() {
      this.personalInformation.name.name = this.state.userInfo.name
      this.personalInformation.name.name ? this.personalInformation.name.isFocused = true : false
      this.personalInformation.positionInCompany.name = this.state.userInfo.position
      this.personalInformation.positionInCompany.name ? this.personalInformation.positionInCompany.isFocused = true : false
      this.personalInformation.surname.name = this.state.userInfo.surname
      this.personalInformation.surname.name ? this.personalInformation.surname.isFocused = true : false
      this.personalInformation.email.name = this.state.userInfo.email
      this.personalInformation.email.name ? this.personalInformation.email.isFocused = true : false
      this.personalInformation.phone.name = this.state.userInfo.phone.number
      this.personalInformation.phone.name ? this.personalInformation.phone.isFocused = true : false
      this.personalInformation.phone.code = this.state.userInfo.phone.code
    }
  },
  watch: {
    isUserInfoLoading(old, newValue) {
      if (newValue) {
        this.changeFields()
      }
    }
  },
  validations: {
    personalInformation: {
      name: {
        name: {
          required,
          minLength: minLength(3)
        }
      },
      surname: {
        name: {
          required,
          minLength: minLength(3)
        }
      },
      positionInCompany: {
        name: {
          required,
          maxLength: maxLength(30)
        }
      },
      confirmPhoneCode: {
        name: {
          minLength: minLength(4)
        }
      },
      phone: {
        name: {
          required,
          numeric,
          maxLength: maxLength(10),
          minLength: minLength(7)
        },
        code: {
          required
        }
      },
      email: {
        name: {
          required,
          email,
          maxLength: maxLength(250),
          isEmailInvalid(email) {
            this.personalInformation.email.loading = true

            if (email === this.state.userInfo.email) {
              this.personalInformation.email.loading = false
              return true
            }

            let userEmail = {
              email: email,
            };
            return FetchAbstraction(false, 'POST', '/api/v1/validation/user', userEmail).then((res) => {
              if (res.message === 'success') {
                return true
              } else if (res.email && res.email.length) {
                this.personalInformation.email.errorText = res.email[0]
              }
            })
              .catch((e) => () => this.personalInformation.email.loading = false)
              .finally(() => this.personalInformation.email.loading = false)
          }
        }
      },
    },
  },
}
</script>

<style scoped lang="scss">
.form__avatar {
  display: flex;
  justify-content: center;
}

.avatarAlt {
  fill: #FFA300;
}

.avatarPreview {
  width: 125px;
  height: 125px;
  object-fit: cover;
}

.avatar__button {
  background: rgba(#FFA300, 1);
  padding: 5px 10px;
  width: 100%;
  border-radius: 10px;
  margin-top: 10px;
}
</style>
